<script>
export default {
  name: 'SejongHeader',
}
</script>

<template>
  <div class="cpr">
    <router-link to="/sejong">
      <div class="logo_wrap">
        <img src="@/assets/img/sejong/sejong_logo.png" />
      </div>
      <h1> 국외정책동향 번역 구축사업</h1>
    </router-link>
  </div>
</template>