import { createRouter, createWebHistory } from 'vue-router'

//사업장 별 파비콘 (LX, EQ)
const FAVICONS = {
  lx: '/lx_favicon.ico',
  eq: '/eq_favicon.ico',
}

// [각 라우트별 메타 데이터 정의 (파비콘, 헤더 메타데이터)]
// 신규 게시판 생성 시 요 부분을 사업 도메인과 동일하게 추가해주세요.
// favicon(상단에서 설정한 사업장 별 파비콘)
// requiresXXXHeader(commonHeader.vue에서 해당 메타데이터로 헤더 분기처리 중) > 이거 꼭 바꿔주세요
// title(게시판 별 브라우저 타이틀)
const ROUTE_META = {
  test: {
    favicon: FAVICONS.lx,
    requiresTestHeader: true,
    title: "Test-Board",
    comment:'test_board'
  },
  sen: {
    favicon: FAVICONS.eq,
    requiresSenHeader: true,
    title: "서울시교육청x에퀴코리아",
    comment:'api'
  },
  cpr: {
    favicon: FAVICONS.lx,
    requiresCPHeader: true,
    title: "CPR",
    comment:'cpr-api'
  },
  moj: {
    favicon: FAVICONS.lx,
    requiresMojHeader: true,
    title: "법무부x렉스코드",
    comment:'moj-api'
  },
  sejong: {
    favicon: FAVICONS.eq,
    requiresSejongHeader: true,
    title: "세종도서관",
    comment:'sejong-api'
  }
}

// [라우트 정의]
// 부모-자식 구조입니다. 잘 확인해서 벌 떠주세요.
// children 구조는 패스(도메인), 네임(웬만하면 컴포넌트 네임이랑 동일하게 해주세요)
// component(실제 파일 경로), meta(상단에서 설정한 메타데이터들)
const routes = [
  // TEST BOARD
  {
    path: '/test',
    meta: ROUTE_META.test,
    children: [
      {
        path: 'login',
        name: 'TestLogin',
        component: () => import('../views/test/loginTest.vue'),
        meta: ROUTE_META.test
      },
      {
        path: '',
        name: 'TestList',
        component: () => import('../views/test/listTest.vue'),
        meta: ROUTE_META.test
      },
      {
        path: 'document/:id/:type',
        name: 'TestDetail',
        component: () => import('../views/test/detailTest.vue'),
        props: true,
        meta: ROUTE_META.test
      },
      {
        path: 'editor/new',
        name: 'TestEditor',
        component: () => import('../views/test/editorTest.vue'),
        meta: ROUTE_META.test
      },
      {
        path: 'editor/:id',
        name: 'TestUpdate',
        component: () => import('../views/test/updateTest.vue'),
        props: true,
        meta: ROUTE_META.test
      }
    ]
  },
  // 서울시교육청 (SEN)
  {
    path: '/sen',
    meta: ROUTE_META.sen,
    children: [
      {
        path: 'login',
        name: 'senLogin',
        component: () => import('../views/sen/loginSen.vue'),
        meta: ROUTE_META.sen
      },
      {
        path: '',
        name: 'senList',
        component: () => import('../views/sen/listSen.vue'),
        meta: ROUTE_META.sen
      },
      {
        path: 'document/:id/:type',
        name: 'senDocument',
        component: () => import('../views/sen/detailSen.vue'),
        props: true,
        meta: ROUTE_META.sen
      },
      {
        path: 'editor/new',
        name: 'senEditor',
        component: () => import('../views/sen/editorSen.vue'),
        meta: ROUTE_META.sen
      },
      {
        path: 'editor/:id',
        name: 'senUpdate',
        component: () => import('../views/sen/updateSen.vue'),
        props: true,
        meta: ROUTE_META.sen
      }
    ]
  },
  // CPR
  {
    path: '/cpr',
    meta: ROUTE_META.cpr,
    children: [
      {
        path: 'login',
        name: 'CPRLogin',
        component: () => import('../views/cpr/loginCPR.vue'),
        meta: ROUTE_META.cpr
      },
      {
        path: '',
        name: 'CPRList',
        component: () => import('../views/cpr/listCPR.vue'),
        meta: ROUTE_META.cpr
      },
      {
        path: 'document/:id/:type',
        name: 'CPRDetail',
        component: () => import('../views/cpr/detailCPR.vue'),
        props: true,
        meta: ROUTE_META.cpr
      },
      {
        path: 'editor/new',
        name: 'CPREditor',
        component: () => import('../views/cpr/editorCPR.vue'),
        meta: ROUTE_META.cpr
      },
      {
        path: 'editor/:id',
        name: 'CPRUpdate',
        component: () => import('../views/cpr/updateCPR.vue'),
        props: true,
        meta: ROUTE_META.cpr
      }
    ]
  },
  // 법무부
  {
    path: '/moj',
    meta: ROUTE_META.moj,
    children: [
      {
        path: 'login',
        name: 'mojLogin',
        component: () => import('../views/moj/loginMoj.vue'),
        meta: ROUTE_META.moj
      },
      {
        path: '',
        name: 'mojList',
        component: () => import('../views/moj/listMoj.vue'),
        meta: ROUTE_META.moj
      },
      {
        path: 'document/:id/:type',
        name: 'mojDetail',
        component: () => import('../views/moj/detailMoj.vue'),
        props: true,
        meta: ROUTE_META.moj
      },
      {
        path: 'editor/new',
        name: 'mojEditor',
        component: () => import('../views/moj/editorMoj.vue'),
        meta: ROUTE_META.moj
      },
      {
        path: 'editor/:id',
        name: 'mojUpdate',
        component: () => import('../views/moj/updateMoj.vue'),
        props: true,
        meta: ROUTE_META.moj
      }
    ]
  },
  // 국회도서관
  {
    path: '/sejong',
    meta: ROUTE_META.sejong,
    children: [
      {
        path: 'login',
        name: 'sejongLogin',
        component: () => import('../views/sejong/loginSejong.vue'),
        meta: ROUTE_META.sejong
      },
      {
        path: '',
        name: 'sejongList',
        component: () => import('../views/sejong/listSejong.vue'),
        meta: ROUTE_META.sejong
      },
      {
        path: 'document/:id/:type',
        name: 'sejongDetail',
        component: () => import('../views/sejong/detailSejong.vue'),
        props: true,
        meta: ROUTE_META.sejong
      },
      {
        path: 'editor/new',
        name: 'sejongEditor',
        component: () => import('../views/sejong/editorSejong.vue'),
        meta: ROUTE_META.sejong
      },
      {
        path: 'editor/:id',
        name: 'sejongUpdate',
        component: () => import('../views/sejong/updateSejong.vue'),
        props: true,
        meta: ROUTE_META.sejong
      }
    ]
  },
]

// 라우터 생성
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 전역 네비게이션 가드 설정 (사업장 별 파비콘 반영)
router.beforeEach((to, from, next) => {
  const { favicon } = to.meta
  if (favicon) {
    const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);
  }
  next();
})

// 페이지 이동 후 스크롤 위치 설정
router.afterEach((to) => {
  window.scrollTo(0, 0);

  // 게시판 별 타이틀 정의
  const title = to.meta.title || 'PMS'
  if(title) document.title = title
})

export default router
